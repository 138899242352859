<template>
  <div>
    <el-button @click="generateCode">
      打印
    </el-button>
    <!-- <el-button @click="generateCode"> 获取图片 </el-button> -->
    <div
      v-if="templateList.length>0"
      class="flexList"
    >
      <!-- id="exportTemplate" -->
      <div
        v-for="(item, index) in templateList"
        :id="[item.idName]"
        :key="index"
        class="exportTemplate"
      >
        <img
          src="https://nb-img.hzanchu.com/acimg/1639982094695.png"
          class="templateBg"
        />
        <div class="hard">
          <p class="hard_wz po_absolu">
            {{ item.anchuPhone }} {{ item.anchuLogo }}
          </p>
          <p class="hard_time po_absolu">
            {{ time2Str(item.createTime).str }}
          </p>
          <p
            v-if="item.transType===2"
            class="hard_name_1 po_absolu"
          >
            航
          </p>
          <p class="hard_sign_te po_absolu">
            <span>{{ promiseTimeObj[item.promiseTimeType] }}</span>
          </p>
          <img
            class="hard_sign_bg po_absolu"
            :src="item.barPackageCode"
          />
          <p class="hard_sign po_absolu">
            {{ item.barPackageNumb }}
          </p>
          <!-- <p class="hard_name_2 po_absolu">尊</p> -->
          <p class="hard_weight po_absolu">
            {{ item.weight }}kg
          </p>
        </div>
        <div class="delive">
          <div class="delive_left ">
            <div class="delive_address">
              {{ item.senderCity }}
            </div>
            <div class="delive_sign">
              {{ item.originalCrossCode }}-{{ item.originalTabletrolleyCode }}
            </div>
          </div>
          <div class="delive_right po_absolu">
            <div class="delive_address">
              {{ item.receiveCity }}
            </div>
            <div class="delive_sign">
              {{ item.destinationCrossCode }}-{{ item.destinationTabletrolleyCode }}
            </div>
          </div>
        </div>
        <div class="content">
          <div class="content_siteName po_absolu">
            <!-- <span class="wordSize19">G</span> -->
            <span class="size19">{{ item.siteName }}</span>
          </div>
          <div class="content_road po_absolu">
            <!-- <span class="wordSize19">COD</span>
            <span class="width5"></span>
            <span class="size19">签单</span> -->
          </div>
          <!-- <div class="content_type size19 po_absolu">众</div> -->
          <div class="content_size po_absolu">
            {{ item.road }}
          </div>
        </div>
        <div class="money">
          <div class="money_c po_absolu">
            <!-- <span class="size19">代收金额：</span>
            <span class="wordSize19">¥113.30</span> -->
          </div>
          <div class="money_r po_absolu">
            <!-- <span class="size19">应收金额：</span>
            <span class="wordSize19">¥113.30</span> -->
          </div>
        </div>
        <div class="receiver">
          <div class="size19 code_type ">
            收
          </div>
          <div class="flex">
            <div class="size19 receiver_name">
              {{ item.receiveName }}
            </div>
            <div
              div
              class="wordSize19 receiver_phone"
            >
              {{ item.receiveMobile }}
            </div>
          </div>
        </div>
        <div class="address">
          {{ item.receiveAddress }}
        </div>

        <div class="send">
          <div class="code_type size19">
            寄
          </div>
          <div class="flex wid540">
            <div class="send_name">
              {{ item.senderName }}
            </div>
            <div class="send_phone">
              {{ item.senderMobile }}
            </div>
            <div class="send_address ellipsis">
              {{ item.senderAddress }}
            </div>
            <!-- <div class="send_type">京东</div> -->
          </div>
        </div>
        <div class="code">
          <img
            class="code_img po_absolu"
            :src="item.barCode"
            alt=""
          />
          <div class="code_word po_absolu">
            {{ item.deliveryId }}
          </div>
        </div>
        <div class="footer">
          <img
            class="QRcode po_absolu"
            :src="item.anchuQrCode"
            alt=" "
          />
          <div class="footer_acName">
            扫码逛农博
          </div>
          <div class="footer_conter po_absolu size19 ellipsis2">
            {{ item.description }}
          </div>
          <div class="footer_order po_absolu">
            <span class="span1"> 订单号：</span><span class="span2">{{ item.orderId }}</span>
          </div>
          <!-- <div class="footer_num po_absolu">{{item.boxCode}}</div> -->
          <!-- <div class="footer_status po_absolu">已验收</div> -->
          <div class="footer_remark po_absolu size19">
            备注:{{ item.remark }}
          </div>
          <!-- <div class="footer_remark po_absolu size19">返单要求:</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';

export default {
  name: 'JdPrint',
  data() {
    return {
      listImg: [],
      isTemplate: true,
      templateList: [],
      promiseTimeObj: {
        '1': '特惠送',
        '2': '特快送',
        '4': '城际闪送',
        '7': '微小件',
        '8': '生鲜专送',
        '16': '生鲜特快',
        '17': '生鲜特惠',
        '20': '函速达',
        '21': '特惠包裹'
      }
    };
  },

  created() {
    this.getJdInfo();
  },
  methods: {
    // 获取京东物流信息
    async getJdInfo() {
      const { orderIds, supplierId } = this.$route.query;
      const res = await this.$axios.post(this.$api.jd.surface, {orderIds, supplierId: +supplierId});
      if (res.code === 0) {
        let data = res.data
        let k = 0
        for (let i in data) {
          let list = data[i]
          for (let j in list) {
            k++
            list[j].idName = 'exportTemplate' + k
          }
          console.log('getJdInfo')
          this.templateList = this.templateList.concat(list)
        }
      }
    },
    // 后续可在视觉上进行优化 如：添加loadding, progress,弹框提示等信息
    async generateCode() {
      let templateList = this.templateList;

      if (this.isTemplate) {
        this.listImg = [];
        for (let i in templateList) {
          const domElement = document.getElementById(templateList[i].idName);
          await html2canvas(domElement, {
            scale: 2,
            useCORS: true
          }).then(canvas => {
            this.isTemplate = false;
            const img = canvas.toDataURL('image/png');
            this.listImg.push(img);
          });
        }
        this.isTemplate = true;
        this.savePdf();
      } else {
        console.log('图片绘制中')
      }
    },
    async getCanvasByHtmlId() {
      const elIdDom = document.getElementById(elId);
      const canvas = await html2canvas(elIdDom, {
        scale: 2,
        dpi: 192,
        // scale:window.devicePixelRatio,
        useCORS: true,
        allowTaint: true,
        // taintTest: false,
        imageTimeout: 0
      }).then(canvas => {
        return canvas;
      });

      return canvas;
    },
    savePdf() {
      // let imgWidth = 200; // 100
      // let imgHeight = 277; // 113
      let imgWidth = 200; // 100
      let imgHeight = 226; // 113

      let position = 10;

      let listImg = this.listImg;
      const pdf = new jsPdf('', 'px', [210, 246]);
      for (let i in listImg) {
        console.log('position', position);
        if (i > 0) {
          pdf.addPage();
          console.log(listImg[i])
        }

        pdf.addImage(listImg[i], 'JPEG', 5, position, imgWidth, imgHeight); // 230, 200 自定义的
        // position-=200;
      }

      // pdf.open("your-filename.pdf");
      // let c="your-filename.pdf"
      // this.list.push("")
      pdf.save();

      const { orderIds, supplierId } = this.$route.query;
      this.$axios.post(this.$api.jd.addPrint, {orderIds, supplierId: +supplierId});
    },
    time2Str(times) {
      let time = new Date(times)
      let obj = {};
      if (times) {
        obj.year = time.getFullYear();
        obj.month = (time.getMonth() + 1) >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1).toString();
        obj.day = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate().toString();
        obj.hour = time.getHours() >= 10 ? time.getHours() : '0' + time.getHours().toString();
        obj.minute = time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes().toString();
        obj.second = time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds().toString();
        obj.str = obj.year + '-' + obj.month + '-' + obj.day + ' ' + obj.hour + ':' + obj.minute + ':' + obj.second;
        return obj;
      } else {
        obj.str = null
        return obj;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.flexList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.exportTemplate {
  width: calc(600px * 0.8);
  height: calc(678px * 0.8);
  position: relative;
  margin-top: calc(100px * 0.8);
  margin-left: calc(100px * 0.8);

  .templateBg {
    width: calc(600px * 0.8);
    height: calc(678px * 0.8);
    position: absolute;
  }
  .hard {
    width: 100%;
    height: calc(181px * 0.8);
    position: relative;
    &_wz {
      width: calc(200px * 0.8);
      height: calc(13px * 0.8);
      font-size: calc(14px * 0.8);
      font-weight: bold;
      color: #000000;
      line-height: calc(14px * 0.8);
      position: absolute;
      top: calc(36px * 0.8);
      left: calc(18px * 0.8);
    }
    &_time {
      width: calc(200px * 0.8);
      height: calc(13px * 0.8);
      font-size: calc(14px * 0.8);
      font-family: D-DIN, D;
      font-weight: normal;
      color: #000000;
      line-height: calc(14px * 0.8);
      top: calc(57px * 0.8);
      left: calc(18px * 0.8);
    }
    &_name_1 {
      width: calc(72px * 0.8);
      height: calc(90px * 0.8);
      font-size: calc(72px * 0.8);
      font-weight: 600;
      color: #000000;
      line-height: calc(90px * 0.8);
      top: 0;
      right: calc(143px * 0.8);
    }
    &_sign_bg {
      width: calc(493px * 0.8);
      height: calc(72px * 0.8);
      top: calc(84px * 0.8);
      left: calc(60px * 0.8);
    }
    &_sign_te {
      width: calc(120px * 0.8);
      height: calc(72px * 0.8);
      background: #000000;
      font-size: calc(31px * 0.8);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      //line-height: calc(72px * 0.8);
      text-align: center;
      top: calc(9px * 0.8);
      right: calc(7px * 0.8);
      display: flex; align-items: center; justify-content: center;
    }
    &_sign {
      width: calc(280px * 0.8);
      height: calc(19px * 0.8);
      font-size: calc(18px * 0.8);
      font-family: D-DINExp-Bold, D-DINExp;
      font-weight: bold;
      color: #000000;
      line-height: calc(19px * 0.8);
      bottom: calc(2px * 0.8);
      left: calc(179px * 0.8);
    }
    &_name_2 {
      width: calc(30px * 0.8);
      height: calc(25px * 0.8);
      font-size: calc(18px * 0.8);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: calc(25px * 0.8);
      bottom: 0;
      left: calc(471px * 0.8);
    }
    &_weight {
      width: calc(70px * 0.8);
      height: calc(25px * 0.8);
      font-size: calc(18px * 0.8);
      font-family: D-DINExp-Bold, D-DINExp;
      font-weight: bold;
      color: #000000;
      line-height: calc(25px * 0.8);
      bottom: calc(0px * 0.8);
      right: calc(56px * 0.8);
      text-align: right;
    }
  }
  .delive {
    width: 100%;
    height: calc(84px * 0.8);
    position: relative;
    &_left {
      width: calc(300px * 0.8);
      height: calc(84px * 0.8);
    }
    &_right {
      width: calc(300px * 0.8);
      height: calc(84px * 0.8);
      right: 0;
      top: 0;
    }
    &_address {
      width: 100%;
      height: calc(32px * 0.8);
      font-size: calc(23px * 0.8);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: calc(32px * 0.8);
      text-align: center;
    }
    &_sign {
      width: 100%;
      height: calc(52px * 0.8);
      font-size: calc(47px * 0.8);
      font-family: D-DINExp-Bold, D-DINExp;
      font-weight: bold;
      color: #000000;
      line-height: calc(52px * 0.8);
      text-align: center;
    }
  }
  .content {
    width: 100%;
    height: calc(49px * 0.8);
    position: relative;
    &_siteName {
      width: calc(270px * 0.8);
      height: calc(24px * 0.8);
      line-height: calc(24px * 0.8);
      position: absolute;
      top: 0;
      left: calc(240px * 0.8);
    }
    &_road {
      width: calc(300px * 0.8);
      height: calc(24px * 0.8);
      line-height: calc(23px * 0.8);
      left: calc(13px * 0.8);
      top: calc(25px * 0.8);
    }
    &_type {
      width: calc(30px * 0.8);
      height: calc(24px * 0.8);
      line-height: calc(24px * 0.8);
      text-align: right;
      right: calc(110px * 0.8);
      top: calc(25px * 0.8);
    }
    &_size {
      width: calc(83px * 0.8);
      height: calc(49px * 0.8);
      font-size: calc(47px * 0.8);
      font-family: D-DINExp-Bold, D-DINExp;
      font-weight: bold;
      color: #000000;
      line-height: calc(49px * 0.8);
      text-align: center;
      top: 0;
      right: calc(6px * 0.8);
    }
  }
  .money {
    position: relative;
    width: 100%;
    height: calc(24px * 0.8);
    &_c {
      width: calc(200px * 0.8);
      height: calc(24px * 0.8);
      line-height: calc(24px * 0.8);
      top: 0;
      left: calc(132px * 0.8);
    }
    &_r {
      width: calc(200px * 0.8);
      height: calc(24px * 0.8);
      line-height: calc(24px * 0.8);
      top: 0;
      right: calc(65px * 0.8);
    }
  }
  .receiver {
    width: 100%;
    height: calc(24px * 0.8);
    position: relative;
    display: flex;
    align-items: center;
    &_name {
      margin-left: calc(100px * 0.8);
    }
    &_phone {
      margin-left: calc(80px * 0.8);
    }
  }
  .address {
    width: 100%;
    height: calc(35px * 0.8);
    font-size: calc(24px * 0.8);
    font-family: STHeiti;
    color: #000000;
    line-height: calc(35px * 0.8);
    position: relative;
    margin-left: calc(12px * 0.8);
    overflow: hidden;
    font-weight: bold;
  }
  .send {
    height: calc(24px * 0.8);
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    font-size: calc(13px * 0.8);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    &_name {
      margin-left: calc(17px * 0.8);
    }
    &_phone {
      margin-left: calc(26px * 0.8);

      font-weight: normal;
      color: #000000;
    }
    &_address {
      margin-left: calc(30px * 0.8);
      max-width: calc(300px * 0.8);
    }
    &_type {
      margin-left: calc(36px * 0.8);
    }
  }
  .code {
    position: relative;
    width: 100%;
    height: calc(77px * 0.8);
    &_img {
      width: calc(300px * 0.8);
      height: calc(57px * 0.8);
      top: calc(2px * 0.8);
      left: calc(30px * 0.8);
    }
    &_word {
      width: calc(300px * 0.8);
      height: calc(16px * 0.8);
      left: calc(48px * 0.8);
      bottom: 0;
      font-weight: bold;
      font-size: calc(14px * 0.8);
    }
  }
}

.footer {
  position: relative;
  width: 100%;
  height: calc(172px * 0.8);
  .QRcode {
    width: calc(54px * 0.8);
    height: calc(54px * 0.8);
    left: calc(24px * 0.8);
    top: calc(5px * 0.8);
  }
  &_conter {
    width: calc(470px * 0.8);
    height: calc(54px * 0.8);
    line-height: calc(27px * 0.8);
    left: calc(100px * 0.8);
  }
  &_order {
    left: calc(100px * 0.8);
    width: calc(470px * 0.8);
    height: calc(23px * 0.8);
    top: calc(62px * 0.8);
    display: flex;
    align-items: center;
    .span1 {
      display: inline-block;
      height: calc(23px * 0.8);
      line-height: calc(23px * 0.8);
      font-size: calc(12px * 0.8);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .span2 {
      width: calc(300px * 0.8);
      height: calc(23px * 0.8);
      font-size: calc(14px * 0.8);
      font-family: D-DINExp-Bold, D-DINExp;
      font-weight: bold;
      color: #000000;
      line-height: calc(23px * 0.8);
      display: inline-block;
      margin-left: calc(5px * 0.8);
    }
  }
  &_num {
    width: calc(280px * 0.8);
    height: calc(111px * 0.8);
    font-size: calc(102px * 0.8);
    font-family: D-DINExp-Bold, D-DINExp;
    font-weight: bold;
    color: #000000;
    line-height: calc(111px * 0.8);
    left: calc(336px * 0.8);
  }
  &_status {
    width: calc(100px * 0.8);
    height: calc(27px * 0.8);
    font-size: calc(19px * 0.8);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: calc(27px * 0.8);
    text-align: right;
    top: calc(38px * 0.8);
    right: calc(18px * 0.8);
  }
  &_remark {
    width: calc(582px * 0.8);
    height: calc(81px * 0.8);
    font-size: calc(19px * 0.8);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: calc(27px * 0.8);
    left: calc(12px * 0.8);
    top: calc(87px * 0.8);
  }
  &_acName {
    position: absolute;
    width: calc(89px * 0.8);
    text-align: center;
    height: calc(18px * 0.8);
    font-size: calc(13px * 0.8);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: calc(18px * 0.8);
    left: calc(6px * 0.8);
    top: calc(62px * 0.8);
  }
}

.code_type {
  height: calc(24px * 0.8);
  line-height: calc(24px * 0.8);
  position: relative;
  top: 0;
  left: calc(24px * 0.8);
  width: calc(40px * 0.8);
}
.po_absolu {
  position: absolute;
}
.size19 {
  font-size: calc(19px * 0.8);
  font-weight: 600;
  color: #000000;
}
.wordSize19 {
  font-family: D-DIN, D;
  font-weight: normal;
  color: #000000;
  font-size: calc(19px * 0.8);
}
.width5 {
  width: calc(5px * 0.8);
  display: inline-block;
}
.flex {
  display: flex;
}
.wid540 {
  width: calc(540px * 0.8);
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
