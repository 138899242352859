var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-button", { on: { click: _vm.generateCode } }, [_vm._v(" 打印 ")]),
      _vm.templateList.length > 0
        ? _c(
            "div",
            { staticClass: "flexList" },
            _vm._l(_vm.templateList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "exportTemplate",
                  attrs: { id: [item.idName] },
                },
                [
                  _c("img", {
                    staticClass: "templateBg",
                    attrs: {
                      src: "https://nb-img.hzanchu.com/acimg/1639982094695.png",
                    },
                  }),
                  _c("div", { staticClass: "hard" }, [
                    _c("p", { staticClass: "hard_wz po_absolu" }, [
                      _vm._v(
                        " " +
                          _vm._s(item.anchuPhone) +
                          " " +
                          _vm._s(item.anchuLogo) +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "hard_time po_absolu" }, [
                      _vm._v(
                        " " + _vm._s(_vm.time2Str(item.createTime).str) + " "
                      ),
                    ]),
                    item.transType === 2
                      ? _c("p", { staticClass: "hard_name_1 po_absolu" }, [
                          _vm._v(" 航 "),
                        ])
                      : _vm._e(),
                    _c("p", { staticClass: "hard_sign_te po_absolu" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.promiseTimeObj[item.promiseTimeType])
                        ),
                      ]),
                    ]),
                    _c("img", {
                      staticClass: "hard_sign_bg po_absolu",
                      attrs: { src: item.barPackageCode },
                    }),
                    _c("p", { staticClass: "hard_sign po_absolu" }, [
                      _vm._v(" " + _vm._s(item.barPackageNumb) + " "),
                    ]),
                    _c("p", { staticClass: "hard_weight po_absolu" }, [
                      _vm._v(" " + _vm._s(item.weight) + "kg "),
                    ]),
                  ]),
                  _c("div", { staticClass: "delive" }, [
                    _c("div", { staticClass: "delive_left" }, [
                      _c("div", { staticClass: "delive_address" }, [
                        _vm._v(" " + _vm._s(item.senderCity) + " "),
                      ]),
                      _c("div", { staticClass: "delive_sign" }, [
                        _vm._v(
                          " " +
                            _vm._s(item.originalCrossCode) +
                            "-" +
                            _vm._s(item.originalTabletrolleyCode) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "delive_right po_absolu" }, [
                      _c("div", { staticClass: "delive_address" }, [
                        _vm._v(" " + _vm._s(item.receiveCity) + " "),
                      ]),
                      _c("div", { staticClass: "delive_sign" }, [
                        _vm._v(
                          " " +
                            _vm._s(item.destinationCrossCode) +
                            "-" +
                            _vm._s(item.destinationTabletrolleyCode) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "content_siteName po_absolu" }, [
                      _c("span", { staticClass: "size19" }, [
                        _vm._v(_vm._s(item.siteName)),
                      ]),
                    ]),
                    _c("div", { staticClass: "content_road po_absolu" }),
                    _c("div", { staticClass: "content_size po_absolu" }, [
                      _vm._v(" " + _vm._s(item.road) + " "),
                    ]),
                  ]),
                  _vm._m(0, true),
                  _c("div", { staticClass: "receiver" }, [
                    _c("div", { staticClass: "size19 code_type" }, [
                      _vm._v(" 收 "),
                    ]),
                    _c("div", { staticClass: "flex" }, [
                      _c("div", { staticClass: "size19 receiver_name" }, [
                        _vm._v(" " + _vm._s(item.receiveName) + " "),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "wordSize19 receiver_phone",
                          attrs: { div: "" },
                        },
                        [_vm._v(" " + _vm._s(item.receiveMobile) + " ")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "address" }, [
                    _vm._v(" " + _vm._s(item.receiveAddress) + " "),
                  ]),
                  _c("div", { staticClass: "send" }, [
                    _c("div", { staticClass: "code_type size19" }, [
                      _vm._v(" 寄 "),
                    ]),
                    _c("div", { staticClass: "flex wid540" }, [
                      _c("div", { staticClass: "send_name" }, [
                        _vm._v(" " + _vm._s(item.senderName) + " "),
                      ]),
                      _c("div", { staticClass: "send_phone" }, [
                        _vm._v(" " + _vm._s(item.senderMobile) + " "),
                      ]),
                      _c("div", { staticClass: "send_address ellipsis" }, [
                        _vm._v(" " + _vm._s(item.senderAddress) + " "),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "code" }, [
                    _c("img", {
                      staticClass: "code_img po_absolu",
                      attrs: { src: item.barCode, alt: "" },
                    }),
                    _c("div", { staticClass: "code_word po_absolu" }, [
                      _vm._v(" " + _vm._s(item.deliveryId) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "footer" }, [
                    _c("img", {
                      staticClass: "QRcode po_absolu",
                      attrs: { src: item.anchuQrCode, alt: " " },
                    }),
                    _c("div", { staticClass: "footer_acName" }, [
                      _vm._v(" 扫码逛农博 "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "footer_conter po_absolu size19 ellipsis2",
                      },
                      [_vm._v(" " + _vm._s(item.description) + " ")]
                    ),
                    _c("div", { staticClass: "footer_order po_absolu" }, [
                      _c("span", { staticClass: "span1" }, [
                        _vm._v(" 订单号："),
                      ]),
                      _c("span", { staticClass: "span2" }, [
                        _vm._v(_vm._s(item.orderId)),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "footer_remark po_absolu size19" },
                      [_vm._v(" 备注:" + _vm._s(item.remark) + " ")]
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "money" }, [
      _c("div", { staticClass: "money_c po_absolu" }),
      _c("div", { staticClass: "money_r po_absolu" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }